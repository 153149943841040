<template>
  <r-e-dialog :title="title" :visible.sync="dialogVisible" :show-footer="type!==0" width="550px"
              @click-submit="handleSubmitPublish" :showFooter="showFooter" @click-cancel="handleCancel" @close="handleCancel">
    <div v-loading="loading"
         :element-loading-text="loadingText"
         element-loading-spinner="el-icon-loading"
         >
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">房间号:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.apartmentName }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">租户:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.leasorName }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">合同编号:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.contractNum }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">签约类型:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.isExchange }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">合同状态:</div>
        <div style="width: 20px;"/>
        <div>
          <el-tag type="success" size="small">{{ objInfo.contractStatusCodeName }}</el-tag>
        </div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">合同周期:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.contractDuration }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">密码有效结束时间:</div>
        <div style="width: 20px;"/>
        <div>{{ objInfo.lockPassEndTime }}</div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px">
        <div style="width: 120px;text-align: right;font-weight: bold">门锁密码有效规则:</div>
        <div style="width: 20px;"/>
        <div>
          <el-radio-group v-model="objInfo.doorLockCodeValidRules">
            <el-radio :label="0" >账单周期</el-radio>
            <el-radio :label="1">合同周期</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div style="margin: 0 auto;display: flex;margin-bottom: 30px;color: #BD3124">
        注意：修改规则成功后即刻生效，对应现有可用密码有效期会根据新规则进行变动；
        若存在可用蓝牙密码，则需租户使用手机连接门锁蓝牙后进行密码效果同步
      </div>
    </div>


  </r-e-dialog>
</template>

<script>

import {updateRuleDetailGET,updateLockRulePOST,getLockPassStatusGET} from "@/api/community-management"
import {MessageError, MessageSuccess, MessageWarning} from "../../../../utils/custom/message";


export default {
  name: "layer-save-coupon",
  data() {
    return {
      title: '修改规则',
      dialogVisible: false,
      loading: false,
      objInfo: {},
      showFooter: true,
      loadingText:'加载中',
      doorLockCodeValidRules:null,
      // from: {
      //   doorLockCodeValidRules: 0,
      // }
    }
  },
  async created() {

  },
  methods: {
    async openDialog(data = null, type = 0) {
      const that = this;
      this.dialogVisible = true;
      this.loading = true
      await updateRuleDetailGET({uuid: data.apartmentUuid}).then(({info}) => {
        this.objInfo = info;
        this.doorLockCodeValidRules = info.doorLockCodeValidRules;
        // console.log(res,'res')
      }).catch(err => {
        MessageError('加载失败');
        this.dialogVisible = false;
        this.loading = true
      }).finally(() => {
        this.loading = false
      })
    },
    //点击确认提交按钮事件
    handleSubmitPublish() {
      if(this.doorLockCodeValidRules===this.objInfo.doorLockCodeValidRules){
        MessageWarning('所需修改规则与当前一致，无需进行修改');
        return
      }
      this.loading = true
      this.loadingText = '拼命修改中......'
      this.showFooter = false
      let params = {
        uuid: this.objInfo.apartmentUuid,
        validRules:this.objInfo.doorLockCodeValidRules,
        lockPassEndTime: this.objInfo.lockPassEndTime,
        contractsUuid: this.objInfo.contractsUuid,
      }
      console.log(params,'params')
      getLockPassStatusGET(params).then((res) => {
        console.log(res.info)
        if(res.info){
          this.$confirm('修改规则后门锁密码有效期比当前有效期短，对应蓝牙密码有效期需租户靠近门锁同步后才能更新，是否确定修改？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleSubmitPublishAPI()
          }).catch(() => {this.showFooter = true;this.loading = false});
        }else {

          this.handleSubmitPublishAPI()
        }
      })
    },
    // 点击确认掉此方法
    handleSubmitPublishAPI(){
      updateLockRulePOST(this.objInfo).then((res) => {
        console.log(res)
        MessageSuccess('更改成功');
        this.dialogVisible = false;
        this.$emit('handleSearch');
      }).catch(err => {
      }).finally(() => {
        this.showFooter = true
        this.dialogVisible = false;
      })
    },
    handleCancel() {
      this.showFooter = true
      this.dialogVisible = false;

      //2022/03/21 调用父组件方法 刷新未支付房租账单列表
      // this.$emit('handleSearch');
    },

  },
  watch: {}
}
</script>

<style lang="scss" scoped>
/deep/ .el-date-editor .el-range-separator {
  padding: 0 5px;
  width: 15%;
  color: #303133;
}
</style>
