<template>
  <section class="door-management">
    <div class="top-bar bg-white" style="align-items: center">
      <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 5px; width: 250px"
                size="small"/>
       <el-select v-model="formSearch.doorLockCodeValidRules" placeholder="请选择门锁规则" style="margin-right: 10px;width: 200px" size="small">
          <el-option label="全部" value=""/>
          <el-option label="账单" :value="0"/>
         <el-option label="合同" :value="1"/>
      </el-select>
      <el-button type="primary" size="small" @click="handleSearch" style="height: 32px">查询</el-button>
      <!--<el-button type="primary" size="small" style="height: 32px">导出</el-button>-->
    </div>
    <div class="table-container bg-white flex">
      <div style="width: 17%;height: 790px;">
        <div style="border-right: 1px #EBEEF5 solid;height: 790px;">
          <r-e-tree title="富航美寓" ref="communityRef" :data="communityTreeData" :node-label="createNodeLabel"
                    :default-expanded-keys="defaultTreeNodeKeys" expand-on-click-node
                    @node-click="handleClickCommunity"
          />
        </div>
      </div>
      <div style="width: 83%;">
        <r-e-table ref="communityTableRef" :dataRequest="getBaseinfoApartment" :columns="tableColumnIndex"
                   :query="formSearch" :height="790" :notAuto="true" :afterDataReq="afterDataReq">
          <el-table-column type="index" fixed label="#" width="50"/>
          <el-table-column slot="toolbar" label="地址" fixed>
            <template slot-scope="{ row }">
              <div class="table-tools">
                <el-link type="primary" :underline="false" @click="openDrawer(row)">
                  {{ row.apartmentName }}
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column slot="toolbar" label="操作" width="250">
            <template slot-scope="{ row }">
              <div class="table-tools">
<!--                <span class="table-btn" @click="AvailabilityVerification(row)">房源核验</span>-->
                <span v-if="contractIsValid(row)" class="table-btn" @click="contractFiling(row)">修改规则</span>
              </div>
            </template>
          </el-table-column>
        </r-e-table>
      </div>
    </div>

    <drawer-equipment-info ref="drawerEquipmentInfo" @handleSearch="handleSearch"/>
    <RulesDialog ref="RulesDialogREF" @handleSearch="handleSearch"></RulesDialog>
  </section>
</template>

<script>
import {tableColumnIndex} from "@/views/property-management/door-management/data";
import {getDoorListApi, getBaseinfoApartment} from "@/api/door-management";
import drawerEquipmentInfo from "@/views/property-management/door-management/components/drawer-equipment-info";
import RulesDialog from "./components/rules-dialog.vue";
import {isApartmentType} from "../../../api/house-resource";
export default {
  name: "door-management",
  data() {
    return {
      tableColumnIndex,
      formSearch: {
        keyword: "",
        uuid: '',
        doorLockCodeValidRules:null
      },
      communityTreeData: [],
      defaultTreeNodeKeys: [],
      mySetInterval: null,
    };
  },
  components: {drawerEquipmentInfo,RulesDialog},
  methods: {
    contractIsValid(row){
      if(row.contractIsValid){
        let itr = row.apartmentName.substring(0, 2);
        if(itr === '五象')return true
      return false
      }
      return false
    },

    handleSearch(isSearch = true) {
      // 搜索
      this.$refs["communityTableRef"].getTableData(isSearch);
    },

    afterDataReq(row) {
      if (row.length === 1) {
        this.openDrawer(row[0]);
      }
      return row;
    },

    getCommunityTableData(params) {
      // 获取智能门锁列表
      return getDoorListApi(params);
    },
    getBaseinfoApartment(params) {
      return getBaseinfoApartment(params);
    },
    openDrawer(row) {
      this.$refs["drawerEquipmentInfo"].openDrawer(row);
    },
    // 修改规则
    contractFiling(row){
      this.$refs.RulesDialogREF.openDialog(row)
      console.log(row)
    },
    createNodeLabel(h, node) {
      // 生成tree节点，房源数量
      return h(
          "span",
          {
            class: "bound-count",
            style: {color: "#2688F6", fontWeight: "bold"}
          },
          node.data.apartmentsCount
      );
    },

    handleClickCommunity(data) {
      const {uuid} = data;
      this.formSearch.uuid = uuid;
      this.handleSearch();
    },

    async getCommunityTreeData(currentNode) {
      // 获取所有小区楼幢单元树tree数据
      const {list = []} = await this.$store.dispatch("community/GetCommunityListActions");
      this.communityTreeData = list;
      for (let item of list) delete item['children'];
    },
    refreshTables() {
      this.handleSearch();
    }
  },
  async mounted() {

    let {params: {data}} = this.$route;
    if (data) {
      const {apartmerntName} = data;
      if (apartmerntName) this.formSearch.keyword = apartmerntName;
    }
    let queryDate = this.$route.query.data
      if(queryDate) this.formSearch.keyword = queryDate.apartmerntName;
    this.handleSearch();
    // 获取所有小区楼幢单元
    await this.getCommunityTreeData();
  },
};
</script>

<style lang="scss" scoped>
.door-management {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: center;
  }

  /deep/ .r-e-table {
    .el-table .cell {
      //white-space: nowrap;
      text-align: center;
    }
  }
}
</style>
